/**
 * Performs a shallow comparison of two objects.
 * Returns true if all top-level keys match strictly, false otherwise.
 *
 * @param objA The first object to compare.
 * @param objB The second object to compare.
 * @returns {boolean} True if both objects are shallowly equal, otherwise false.
 */
export function shallowEquals<T extends object>(objA: T, objB: T): boolean {
  // Same reference short-circuit
  if (objA === objB) return true;

  // Handle null/undefined mismatch
  if (!objA || !objB) return false;

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  // If they have a different number of keys, they can't be equal
  if (keysA.length !== keysB.length) return false;

  // Check each key's value strictly
  for (const key of keysA) {
    if (objA[key] !== objB[key]) {
      return false;
    }
  }

  return true;
}
