import {
  ArrayUtils,
  ColumnOrderMap,
  DataGridColDef,
  DataGridColumnResizeWidthMap,
  DataGridColumnVisibilityMap,
  DataGridProps,
  DataGridRenderBodyCellParams,
  DataGridRowModel,
  DataGridSortField,
  extractSortPreferencesFromLocalStorage,
  IPagedResult,
} from '@dierbergs-markets/react-component-library';
import ContractActionMenuButton from '../components/ContractActionMenuButton';
import { tss } from 'tss-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IContractTimeFrame } from '../../../../../models';
import { format } from 'date-fns';
import { searchGridStyles } from '../styles';
import { HttpErrorResponse, toDateOnlyString } from '../../../../../services/contractHubApi';
import { ICommonPredefinedSearchParams } from '../../../../../models/requests';
import { useImmer } from 'use-immer';
import { ICommonContractSearchResponse } from '../../../../../models/responses';

const minColumnWidth = 55;

export const CommonSearchDataGridFields = {
  CategoryManagerName: 'categoryManagerName',
  CustomerId: 'customerId',
  CustomerName: 'customerName',
  SupplierId: 'supplierId',
  SupplierName: 'supplierName',
  VendorContractNumber: 'vendorContractNumber',
  Manufacturer: 'manufacturer',
  ProductLine: 'productLine',
  Comments: 'comments',
  ContractId: 'contractId',
  StartDate: 'startDate',
  StateText: 'stateText',
  Proposal: 'proposal',
  Actions: 'actions',
};

/**
 * A type representing an object where the keys are strings and the values are partial
 * configurations for DataGrid columns.
 *
 * @type {Object.<string, Partial<DataGridColDef>>} CommonSearchDataGridColDefOverride
 * @property {string} key - The name of the column, used as the key in the object.
 * @property {Partial<DataGridColDef>} value - Partial definition for the DataGrid column configuration.
 * See {@link DataGridColDef}
 */
export type CommonSearchDataGridColDefOverride = { [key: string]: Partial<DataGridColDef> };

interface IProps<TQueryParams, R> {
  gridId: string;
  timeFrame: IContractTimeFrame;
  performSearch: (searchQuery: TQueryParams, signal: AbortSignal) => Promise<IPagedResult<R> | HttpErrorResponse>;
  handleSearchError: (message: string) => void;
}
export default function useCommonSearchGridConfig<TQueryParams extends ICommonPredefinedSearchParams, R = DataGridRowModel>(
  props: IProps<TQueryParams, R>
) {
  //VARS
  const LOCALSTORAGEKEY_COLUMN_VISIBILITY = `${props.gridId}.preferences.colVisibility`;
  const LOCALSTORAGEKEY_COLUMN_ORDER = `${props.gridId}.preferences.colOrder`;
  const LOCALSTORAGEKEY_SORT = `${props.gridId}.preferences.sort`;
  const LOCALTORAGEKEY_COLUMN_RESIZES = `${props.gridId}.preferences.colResizes`;
  const localStorageColumnVisibility = localStorage.getItem(LOCALSTORAGEKEY_COLUMN_VISIBILITY);
  const localStorageColumnOrder = localStorage.getItem(LOCALSTORAGEKEY_COLUMN_ORDER);
  const localStorageColumnResizes = localStorage.getItem(LOCALTORAGEKEY_COLUMN_RESIZES);
  const localStorageSort = localStorage.getItem(LOCALSTORAGEKEY_SORT);

  const defaultPageSize = 150;
  const defaultSort: DataGridSortField[] = [{ column: CommonSearchDataGridFields.ContractId, direction: 'DESC' }];

  const commonSearchGridColumns: DataGridColDef[] = [
    {
      field: CommonSearchDataGridFields.CategoryManagerName,
      headerName: 'Category Manager',
      type: 'string',
      width: 200,
      minWidth: minColumnWidth,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.CustomerId,
      headerName: 'Bill to Account #',
      type: 'string',
      width: 150,
      minWidth: minColumnWidth,
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.CustomerName,
      headerName: 'Bill to Acct Name',
      width: 150,
      minWidth: minColumnWidth,
      type: 'string',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.SupplierId,
      headerName: 'Supplier #',
      width: 100,
      minWidth: minColumnWidth,
      type: 'string',
      sortable: false,
      filterable: true,
      resizable: true,
      renderBodyCellContent: (x) => {
        const value = x.value || '-';
        return <span title={value}>{value}</span>;
      },
    },
    {
      field: CommonSearchDataGridFields.SupplierName,
      headerName: 'Supplier Name',
      width: 200,
      minWidth: minColumnWidth,
      type: 'string',
      sortable: false,
      filterable: true,
      resizable: true,
      renderBodyCellContent: (x) => {
        const value = x.value || '-';
        return <span title={value}>{value}</span>;
      },
    },
    {
      field: CommonSearchDataGridFields.VendorContractNumber,
      headerName: 'Contract #',
      width: 270,
      minWidth: minColumnWidth,
      type: 'string',
      filterable: true,
      sortable: true,
      hideable: false,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.Manufacturer,
      headerName: 'Manufacturer',
      width: 200,
      minWidth: minColumnWidth,
      type: 'string',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.ProductLine,
      headerName: 'Product Line',
      width: minColumnWidth,
      type: 'string',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.Comments,
      headerName: 'Comments',
      type: 'string',
      filterable: true,
      sortable: true,
      width: 200,
      minWidth: minColumnWidth,
      resizable: true,
      renderBodyCellContent: (x) => {
        return (
          <div className={classes.comments} title={x.value}>
            {x.value}
          </div>
        );
      },
    },
    {
      field: CommonSearchDataGridFields.ContractId,
      headerName: 'Internal Contract #',
      width: 175,
      minWidth: minColumnWidth,
      type: 'number',
      filterable: true,
      sortable: true,
      hideable: false,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.StartDate,
      headerName: 'Min. Start Date',
      width: 150,
      minWidth: minColumnWidth,
      filterable: false,
      type: 'string',
      sortable: true,
      renderBodyCellContent: (x) => format(new Date(x.value), 'MM/dd/yy'),
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.StateText,
      headerName: 'Status',
      width: 150,
      minWidth: minColumnWidth,
      type: 'string',
      filterable: true,
      sortable: true,
      resizable: true,
    },
    {
      field: CommonSearchDataGridFields.Proposal,
      headerName: 'Proposal',
      width: 100,
      minWidth: minColumnWidth,
      type: 'string',
      filterable: false,
      sortable: false,
      resizable: true,
      renderBodyCellContent: (x) => {
        const value = x.row.proposal ? 'Yes' : 'No';
        return <span title={value}>{value}</span>;
      },
    },
    {
      field: CommonSearchDataGridFields.Actions,
      headerName: '',
      type: 'string',
      filterable: false,
      sortable: false,
      width: 65,
      minWidth: minColumnWidth,
      fixedAlignPosition: 'right',
      hideable: false,
      renderBodyCellContent: (x: DataGridRenderBodyCellParams<string, ICommonContractSearchResponse>) => (
        <ContractActionMenuButton
          contractId={x.row.contractId}
          allowOrderSurvey={props.gridId === 'orderSurveyDataGrid'}
          onOrderSurveyProcessed={refreshGrid}
        />
      ),
    },
  ];

  const defaultSearchQuery = {
    page: {
      number: 0,
      size: defaultPageSize,
    },
    startDate: toDateOnlyString(props.timeFrame.startDate),
    sort: defaultSort,
  } as TQueryParams;

  //MISC HOOKS
  const { classes } = useStyles();

  //REFs
  const controllerRef = useRef<AbortController | null>(null);

  //STATE
  const [loading, setLoading] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [gridColumns, setGridColumns] = useState<DataGridColDef[]>(commonSearchGridColumns);
  const [dataOnScroll, setDataOnScroll] = useState<R[]>([]);
  const [searchQuery, setSearchQuery] = useImmer<TQueryParams | null>(null);
  const [forceRefreshTrigger, setForceRefreshTrigger] = useState<number | null>(null);
  const [onRowsScrollEndEnabled, setOnRowsScrollEndEnabled] = useState(true);

  const [columnVisibility, setColumnVisibility] = useState<DataGridColumnVisibilityMap>(
    localStorageColumnVisibility ? JSON.parse(localStorageColumnVisibility) : defaultVisiblityMap
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderMap>(localStorageColumnOrder ? JSON.parse(localStorageColumnOrder) : {});

  const [columnResizes, setColumnResized] = useState<DataGridColumnResizeWidthMap>(
    localStorageColumnResizes ? JSON.parse(localStorageColumnResizes) : {}
  );

  //USE EFFECTS
  useEffect(() => {
    let sortFields: DataGridSortField[] = defaultSort;
    const extractedSortPreferences = extractSortPreferencesFromLocalStorage(localStorageSort);

    if (extractedSortPreferences && extractedSortPreferences.length > 0) {
      sortFields = extractedSortPreferences;
    }

    setSearchQuery((state) => {
      if (!state) return defaultSearchQuery;

      state.startDate = toDateOnlyString(props.timeFrame.startDate);
      state.endDate = props.timeFrame.startDate && toDateOnlyString(props.timeFrame.endDate);
      state.sort = sortFields;
    });

    setDataOnScroll([]);
  }, [props.timeFrame]);

  useEffect(() => {
    if (!searchQuery) return;
    (async () => await handleSearch())();
  }, [searchQuery]);

  useEffect(() => {
    (async () => {
      if (forceRefreshTrigger) await handleSearch();
    })();
  }, [forceRefreshTrigger]);

  async function handleSearch() {
    if (!searchQuery) return;

    setLoading(true);

    // Cancel the previous request
    const previousController = controllerRef.current;
    if (previousController) {
      previousController.abort();
    }

    // Start a new request
    const newController = new AbortController();
    controllerRef.current = newController;

    try {
      const response = await props.performSearch(searchQuery, newController.signal);

      if (response instanceof HttpErrorResponse) {
        if (!response.isCanceled) {
          props.handleSearchError('Unable to retrieve data.');
        }
      } else {
        if (searchQuery.page.number === 0) {
          setDataOnScroll(response.results);
        } else {
          setDataOnScroll((prev) => ArrayUtils.distinct([...prev, ...response.results]));
        }
        setTotalCount(response.totalResults);
      }
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        console.warn('Request was aborted as expected.');
      } else if (error instanceof Error) {
        console.error('Unexpected error:', error.message);
        props.handleSearchError('An unexpected error occurred.');
      } else {
        console.error('An unknown error occurred:', error);
      }
    } finally {
      setLoading(false);
      setOnRowsScrollEndEnabled(true);
    }
  }

  //FUNCTIONS
  function handleColumnVisibilityChange(map: DataGridColumnVisibilityMap) {
    setColumnVisibility(map);
    localStorage.setItem(LOCALSTORAGEKEY_COLUMN_VISIBILITY, JSON.stringify(map));
  }

  function handleColumnOrderChange(order: ColumnOrderMap) {
    setColumnOrder(order);
    localStorage.setItem(LOCALSTORAGEKEY_COLUMN_ORDER, JSON.stringify(order));
  }

  function handleColumnResize(map: DataGridColumnResizeWidthMap): void {
    setColumnResized(map);
    localStorage.setItem(LOCALTORAGEKEY_COLUMN_RESIZES, JSON.stringify(map));
  }

  function defaultVisiblityMap(): DataGridColumnVisibilityMap {
    return gridColumns.reduce((acc, cur, index) => {
      const fieldName = cur.field || `column_${index}`;

      return { ...acc, [fieldName]: true };
    }, {});
  }

  const refreshGrid = async () => {
    //creates new number triggering a grid refresh.
    setForceRefreshTrigger(Date.now());
  };

  /**
   * Generates a list of DataGrid columns by merging default column configurations with overrides.
   * @param {CommonSearchDataGridColDefOverride} [overrides={}] - An object containing overrides for specific DataGrid columns. 
   * The keys are the column field names, and the values are partial configurations to override the default column settings.
   * @returns {DataGridColDef[]} A list of DataGrid column definitions with the applied overrides.
   * See {@link DataGridColDef}, {@link CommonSearchDataGridColDefOverride}
   * 
   * @example 
     //To edit the width of categoryManager and customerName columns:
    const columnOverrides: CommonSearchDataGridColDefOverride = {
        [CommonSearchDataGridFields.CustomerName]: {
          headerName: 'New Header Name', //set different header name
        },
        [CommonSearchDataGridFields.CategoryManagerName]: {
          width: 400, //set different width
        },
      };

      //Pull setGridColumns, getCommonSearchDataGridColumns out of useCommonSearchGridConfig hook  
      const { getCommonSearchDataGridColumns, setGridColumns } = useCommonSearchGridConfig();
      
      useEffect(() => {
      setGridColumns([...getCommonSearchDataGridColumns(columnOverrides)]);
      }, []);
 */
  const getCommonSearchDataGridColumns = (overrides: CommonSearchDataGridColDefOverride = {}) => {
    return commonSearchGridColumns.map((column) => ({
      ...column,
      ...(overrides[column.field] || {}),
    }));
  };

  function handleSortingColumnChange(fields: DataGridSortField[]) {
    setDataOnScroll([]);
    setSearchQuery((state) => {
      if (!state) return defaultSearchQuery;
      state.sort = fields;
      state.page.number = 0;
    });
  }

  const handleOnRowsScrollEnd = useCallback(() => {
    if (onRowsScrollEndEnabled) {
      if (dataOnScroll.length < totalCount) {
        setSearchQuery((state) => {
          if (!state) return defaultSearchQuery;

          state.page.number = state.page.number + 1;
        });
      }
      setOnRowsScrollEndEnabled(false); // Prevent further calls until reset
    }
  }, [defaultSearchQuery, setSearchQuery, onRowsScrollEndEnabled]);

  const defaultDataGridProps: DataGridProps = {
    id: props.gridId,
    columnOrderable: true,
    rows: dataOnScroll,
    columns: gridColumns,
    bodyRowHeight: 52,
    headerRowHeight: 56,
    isLoading: loading,
    cssOverrides: {
      root: searchGridStyles.gridOverrideRoot,
      header: searchGridStyles.gridOverrideHeader,
    },
    preferences: {
      columns: { visibility: columnVisibility, order: columnOrder, resizeWidths: columnResizes },
    },
    onColumnVisibilityChange: handleColumnVisibilityChange,
    onColumnOrderChange: handleColumnOrderChange,
    onColumnSortChange: handleSortingColumnChange,
    onColumnResize: handleColumnResize,
    onRowsScrollEnd: handleOnRowsScrollEnd,
    defaultSort,
  };

  return {
    defaultDataGridProps,
    gridColumns,
    setGridColumns,
    searchQuery: searchQuery ?? defaultSearchQuery,
    setSearchQuery,
    setDataOnScroll,
    getCommonSearchDataGridColumns,
    defaultPageSize,
    defaultSort,
  };
}

const useStyles = tss.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    zIndex: 10,
    width: '100%',
  },
  comments: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
