import { IContract, IPricing, IPricingItem } from '../../../../../models';
import { DataGridCellMessageMap, DataGridRenderBodyCellParams } from '@dierbergs-markets/react-component-library';
import * as PG from '@dierbergs-markets/react-feature-library';
import { tss } from 'tss-react';
import { CurrencyCellContent, PricingGrid } from '@dierbergs-markets/react-feature-library';
import { memo, useEffect, useState } from 'react';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { defaultColors } from '../../../../../styles/variables';
import { getContractPricingItems, getPricingColumnConfigurations, PricingGridPricingItem } from '../../../../../utilities/PricingUtilities';
import { Circle, Inventory, Remove } from '@mui/icons-material';
import PricingSourceDialog from './PricingSourceDialog';

interface IProps {
  contract: IContract;
  canModify: boolean;
  contractLoading: boolean;
  onPricingEdit: (price: IPricing) => void;
  onRowUpdate: (row: PG.IPricingItem, messages?: DataGridCellMessageMap) => void;
  onMultiRowUpdate: (rows: PG.IPricingItem[]) => void;
  onPricingItemsUpdate: (pricingIndex: number, items: IPricingItem[]) => void;
}

const PricingGridContainer = memo((props: IProps) => {
  // STATE
  const [gridRows, setGridRows] = useState<PricingGridPricingItem[]>([]);
  const [gridMessages, setGridMessages] = useState<DataGridCellMessageMap | undefined>(undefined);
  const [sourceDialogSelectedRow, setSourceDialogSelectedRow] = useState<PricingGridPricingItem | null>(null);

  // MISC. HOOKS
  const { referenceData, user } = useApplicationContextState();
  const { classes } = useStyles();

  // EFFECTS
  useEffect(() => {
    if (referenceData) setGridRows(getContractPricingItems(props.contract, referenceData));
  }, [props.contract, referenceData]);

  // FUNCTIONS
  function handleRowFinalize(row: any, messages?: DataGridCellMessageMap) {
    props.onRowUpdate(row, messages);
    setGridMessages(messages);
  }

  function invokePricingEdit(pricing: IPricing) {
    props.canModify && props.onPricingEdit(pricing);
  }

  return (
    <div className={classes.root}>
      {referenceData && gridRows.length > 0 && (
        <PricingGrid
          id={'PricingGrid'}
          prices={getPricingColumnConfigurations(
            props.contract,
            props.canModify,
            referenceData,
            classes,
            invokePricingEdit,
            props.onPricingItemsUpdate
          )}
          findReplaceEnabled={true}
          columnOrderable
          maxBodyHeightOffset={496}
          headerRowHeight={160}
          rows={gridRows}
          actionConfig={[{ position: 'bottom', actions: ['Columns', 'Sort'], alignment: 'left' }]}
          processRowUpdate={handleRowFinalize}
          processMultiRowUpdate={props.onMultiRowUpdate}
          preferences={{
            columns: {
              visibility: {
                suggestedRetail: true,
                source: true,
                'item.orderCode': true,
              },
              order: {
                source: 3.1,
                suggestedRetail: 10.1,
              },
            },
          }}
          additionalColumns={[
            {
              field: 'source',
              headerName: 'Source',
              type: 'string',
              width: 55,
              renderBodyCellContent(params: DataGridRenderBodyCellParams<any, PricingGridPricingItem>) {
                const icon = params.row.source?.shipper ? <Inventory /> : params.row.source ? <Circle /> : <Remove />;

                return (
                  <div style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setSourceDialogSelectedRow(params.row)}>
                    {icon}
                    {params.row.sources.length > 1 ? '..' : ''}
                  </div>
                );
              },
            },
            {
              field: 'suggestedRetail',
              headerName: 'Sugg Retail',
              type: 'string',
              width: 95,
              renderBodyCellContent(params: DataGridRenderBodyCellParams<number, PricingGridPricingItem>) {
                if (!params.row.suggestedRetailPrice) return <></>;
                return <CurrencyCellContent value={params.row.suggestedRetailPrice} multiple={params.row.suggestedRetailMultiple} />;
              },
            },
          ]}
          costImplicationGroupedHeaderCellCss={{
            root: {
              backgroundColor: defaultColors.white,
              borderRadius: '16px 16px 0 0',
              outline: `1px solid ${defaultColors.grey}`,
              fontSize: '16px',
              paddingTop: '13px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
            subRow: {
              marginTop: '10px',
            },
          }}
        />
      )}
      <PricingSourceDialog selectedRow={sourceDialogSelectedRow} onClose={() => setSourceDialogSelectedRow(null)} />
    </div>
  );
});

export default PricingGridContainer;

const useStyles = tss.create({
  root: {},

  headerContents: {
    margin: '15px',
    marginBottom: '10px',
    marginLeft: '20px',
    width: '100%',
  },
  headerComments: {
    width: '280px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerEditBtn: {
    '&&': {
      fontWeight: 500,
      lineHeight: '19px',
      fontSize: '16px',
      color: defaultColors.blue,
      paddingBottom: '5px',
    },
  },
  suggestedRetail: {
    paddingTop: '8px',
  },
  pricePrefix: {
    paddingRight: '8px',
    fontWeight: 500,
  },
});
