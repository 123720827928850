import { defaultBorders, defaultColors } from '../../../../styles/variables';
import { formControlClasses } from '@mui/material';
import { tss } from 'tss-react';

export const useAdPlannerExportStyles = tss.create({
  root: {
    padding: '10px 20px',
    boxSizing: 'border-box',
    overflow: 'hidden',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    flexGrow: 0,
    flexShrink: 0,
    marginBottom: '10px',
  },
  mainContent: {
    height: 'calc(100vh - 190px)',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box',
    padding: '0 10%',
    marginBottom: '15px',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  centeredMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 300px)',
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: 500,
    spinner: {
      margin: '10px',
      color: defaultColors.blue,
    },
  },
  startDate: {
    width: '150px',
    [`& .${formControlClasses.root}`]: {
      height: 60,
    },
  },
  adSite: {
    width: '200px',
    [`& .${formControlClasses.root}`]: {
      height: 60,
    },
  },
  footerContainer: {
    width: '100%',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  exportButton: {
    marginRight: '18px',
    backgroundColor: defaultColors.blue,
    color: defaultColors.white,
  },
  exportButtonProgress: {
    position: 'relative',
    left: '45px',
    top: '10px',
    height: '25px',
    width: '25px',
    marginRight: '5px',
    color: defaultColors.lightGrey,
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  title: {
    fontSize: '35px',
    marginRight: '14px',
  },
  subTitle: {
    fontSize: '25px',
    marginRight: '14px',
  },
  sectionSpace: {
    paddingTop: '15px',
  },
  subSectionSpaceWrapper: {
    '::after': {
      content: '""',
      display: 'inherit',
      width: '100%',
      margin: '10px auto',
      borderTop: `1px solid ${defaultColors.grey}`,
    },
  },
  subSectionSpace: {
    paddingTop: '10px',
    display: 'flex',
  },
  checkboxWrapperAdGroup: {
    height: '70px',
  },
  checkboxWrapperModule: {
    height: '28px',
  },
  smallTitle: {
    fontSize: '20px',
    paddingLeft: '7px',
  },
  groupNameAndCommentContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    columnGap: '5px',
    marginTop: '5px',
    marginBottom: '15px',
    padding: '5px',
    width: '100%',
  },
  groupNameContainer: {
    width: '75%',
    minWidth: '280px',
  },
  groupNameErrorText: {
    bottom: '-5px !important',
  },
  groupCommentContainer: {
    flexGrow: 2,
  },
  groupAdSiteIdContainer: {
    fontSize: '12px',
    color: defaultColors.mediumGrey,
    padding: '8px 10px 0px 10px',
    height: '60px',
    width: '500px',
  },
  groupAdSiteIdLabel: {
    paddingBottom: '5px',
  },
  groupAdSiteIdName: {
    color: defaultColors.darkGrey,
    fontWeight: 'bold',
  },
  groupAdSiteIdMissing: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    color: defaultColors.red,
    fontWeight: 'bold',
  },
  groupAdSiteIdContractLink: {
    fontWeight: 500,
    textDecoration: 'underline',
  },
  groupAdSiteStartDateContainer: {
    flexGrow: 1,
  },
  groupSelect: {
    height: '27px',
  },
  groupStoresContainer: {
    paddingTop: '12px',
  },
  propertyDetailsContainer: {
    width: '100%',
  },
  propertyDetails: {
    width: '100%',
    marginBottom: '12px',
    '& label': {
      fontWeight: 'bold',
      marginRight: '5px',
    },
  },
  adGroups: {
    padding: '10px',
  },
  gridTable: {
    width: '100%',
    borderCollapse: 'collapse',
    margin: '0 0 25px 0',
    textAlign: 'left',
    tableLayout: 'fixed',
    '& th, & td': {
      padding: '12px 15px',
      border: `1px solid ${defaultColors.border}`,
    },
    '& tbody tr:hover': {
      backgroundColor: defaultColors.transparentMediumGrey,
    },
  },
  contractLink: {
    color: defaultColors.blue,
    cursor: 'pointer',
    '&:active': {
      color: defaultColors.blue,
    },
    '&:visited': {
      color: defaultColors.blue,
    },
    '&:hover': {
      color: defaultColors.lightBlue,
    },
  },
  contractPageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  headerBtn: {
    color: defaultColors.darkGrey,
    backgroundColor: defaultColors.transparent,
    fontWeight: 500,
    fontSize: '24px',
    marginBottom: 'auto',
    marginTop: 'auto',
    width: 'initial',
    padding: 0,
    '&:hover': {
      backgroundColor: defaultColors.lightGrey,
    },
  },
  headerBtnIcon: {
    fontSize: '22px',
    width: '22px',
  },
  adStartDateColumn: {
    width: '250px',
  },
});
