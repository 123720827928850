import {
  alpha,
  Box,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogTitle,
  OutlinedInput,
  outlinedInputClasses,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ScaleIcon from '@mui/icons-material/Scale';
import { defaultBorders, defaultColors } from '../../../../../styles/variables';
import SearchIcon from '../../../../../images/search.svg';
import { StyledImg } from '../../../../../styles/styled/StyledWrappedCommonElements';
import { DbgButtonSize, UpcSelectionSearchType } from '../../../../../models/enums';
import { getGreenButtonStyleProps, getLightGreyButtonStyleProps, getWhiteButtonStyleProps } from '../../../../../styles/themes';
import { DbgRoundedButton } from '../../../../components/buttons/DbgRoundedButton';
import { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import { itemService } from '../../../../../services';
import { IUpcSearchForContractResponse } from '../../../../../models/responses';
import { IChildContractItem, IContractItem, IItem, IStoreSelection, ISupplier } from '../../../../../models';
import { v4 } from 'uuid';
import { DbgLoadingSpinner } from '../../../../components/shared/DbgLoadingSpinner';
import {
  DataGrid,
  DataGridColDef,
  DataGridRenderBodyCellParams,
  DataGridRowId,
  DataGridRowModel,
  useMemoForAutoCompleteValue,
} from '@dierbergs-markets/react-component-library';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import UpcSearchNotification from '../../../Dashboard/search/components/UpcSearchNotification';

interface IProps {
  id: string;
  supplierId?: number; //remove nullable?
  storeSelections: IStoreSelection;
  supplier?: ISupplier;
  open: boolean;
  sx?: SxProps;
  onAddToContract: (contractItems: IContractItem[]) => void;
  onCancel: () => void;
}

interface IStyleProps {
  dialogContentHeight: number;
  searchContainerHeight: number;
  associatedItemsRowCount: number;
}

const myStyles = (props: IStyleProps) => {
  const sectionMargin = 36;
  const headerHeight = 45;
  const rowHeight = 60;
  const foundItemHeight = 140;
  let associatedItemsHeight = foundItemHeight; //default

  const associatedItemsAvailableSpace = props.dialogContentHeight - (props.searchContainerHeight + foundItemHeight + sectionMargin * 2);

  if (props.associatedItemsRowCount > 1) {
    const rawAssociatedItemsHeight = headerHeight + rowHeight * props.associatedItemsRowCount + 50; //50 is a magic number that got scrollbar to disappear when not needed.

    const allSectionsTotalHeight = props.searchContainerHeight + foundItemHeight + rawAssociatedItemsHeight;

    if (allSectionsTotalHeight <= associatedItemsAvailableSpace) {
      associatedItemsHeight = allSectionsTotalHeight;
    } else {
      associatedItemsHeight =
        rawAssociatedItemsHeight <= associatedItemsAvailableSpace ? rawAssociatedItemsHeight : associatedItemsAvailableSpace - sectionMargin;
    }
  }
  return {
    missingOrderCode: {
      content: {
        paddingTop: '20px',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '27px',
      },
    },
    dialog: {
      root: {
        '& .MuiBackdrop-root': {
          backgroundColor: alpha(defaultColors.darkGrey, 0.5),
        },
        [`& .${dialogClasses.paper}`]: {
          borderRadius: '20px',
          color: defaultColors.darkGrey,
          textAlign: 'center',
          minWidth: '75%',
          minHeight: '85%',
        },
      },

      title: {
        marginTop: '22px',
        marginRight: '22px',
        padding: '0px',
        display: 'flex',
        justifyContent: 'flex-end',
        closeIcon: {
          width: '20px',
          height: '20px',
          color: defaultColors.mediumGrey,
          cursor: 'pointer',
          display: 'unset',
        },
      },
      content: {
        padding: '0px',
        paddingBottom: '20px',
        marginX: '29px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      },
      actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '29px',
        marginRight: '29px',
        padding: '0px',
        btnAddToContract: {
          color: defaultColors.white,
          fontWeight: 500,
          width: '195px',
          height: '45px',
        },
      },
    },
    search: {
      marginBottom: `${sectionMargin}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 'auto',
      instructions: {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: 0,
        fontWeight: 500,
        marginBottom: '20px',
      },
      form: {
        display: 'flex',
        justifyContent: 'center',
        toggle: {
          marginRight: '5px',
          borderRadius: '12px',
          height: '44px',
          fontSize: '15px',
          lineHeight: '19px',
          '& .Mui-disabled': {
            backgroundColor: `${defaultColors.blue} !important`,
            color: `${defaultColors.white} !important`,
            cursor: 'default',
          },
          '& :hover': {
            backgroundColor: `${alpha(defaultColors.grey, 0.4)}`,
          },

          upc: {
            width: '68px',
            textTransform: 'unset',
          },
          orderCode: {
            width: '135px',
            textTransform: 'unset',
          },
        },
        input: {
          marginLeft: '5px',
          [`&.${outlinedInputClasses.root}`]: {
            backgroundColor: defaultColors.lightGrey,
            width: '281px',
            marginRight: '42px',
            height: '44px',
            borderRadius: '12px',
            boxShadow: defaultBorders.boxShadow,
            borderColor: defaultColors.border,
            '& fieldset': {
              '&.Mui-focused': {
                borderWidth: '0px !important',
              },
              borderWidth: '1px !important',
              borderColor: `${defaultColors.border} !important`,
            },
            [`& .${outlinedInputClasses.input}`]: {
              textAlign: 'center',
              fontWeight: 500,
              fontSize: '20px',
              '&::placeholder': {
                color: defaultColors.grey,
              },
            },
          },
          error: {
            [`&.${outlinedInputClasses.root}`]: {
              borderColor: defaultColors.red,
              boxShadow: `0 0 0px 2px ${defaultColors.red}`,
            },
          },
        },
        button: {
          color: defaultColors.mediumGrey,
          borderRadius: '12px',
          fontSize: '15px',
          lineHeight: '19px',
          border: `1px solid ${defaultColors.border}`,
          height: '44px',
          searchIcon: {
            position: 'relative',
            top: '-2px',
            width: '18px',
            height: '18px',
            color: defaultColors.grey,
            marginRight: '10px',
          },
          searchIconLoading: {
            height: '18px !important',
            width: '18px !important',
            color: defaultColors.mediumGrey,
          },
        },
      },
    },
    dataGridContainer: {
      marginBottom: `${sectionMargin}px`,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      heading: {
        textAlign: 'left',
        marginBottom: '14px',
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 500,
      },
      foundItem: {
        height: `${foundItemHeight}px`,
        minHeight: `${foundItemHeight}px`,
      },
      associatedItems: {
        height: `${associatedItemsHeight}px`,
        minHeight: `${associatedItemsHeight}px`,
        marginBottom: '0px',
      },
    },
    dataGrid: {
      root: {
        width: '100%',
        borderRadius: '16px',
        border: `1px solid ${defaultColors.border}`,
        overflow: 'hidden',
      },
      header: {
        backgroundColor: defaultColors.lightGrey,
        borderRadius: '0px',
        lineHeight: 'unset !important',
        height: `${headerHeight}px !important`,
        minHeight: `${headerHeight}px !important`,
        maxHeight: `${headerHeight}px !important`,
        boxShadow: 'none',
        borderBottom: `1px solid ${defaultColors.border}`,
      },
      headerCell: {
        content: '""', // typescript requires something here before the below property - content chosen at random
        '[role="checkbox"] label': {
          '&::before': {
            top: '32%',
          },
          '&::after': {
            top: 'calc(50% - 9px)',
          },
        },
      },
      headerCellSingleRow: {
        content: '""', // typescript requires something here before the below property - content chosen at random
        '[role="checkbox"]': {
          display: 'none',
        },
      },
      bodyRow: {
        borderBottom: `1px solid ${defaultColors.border}`,
        '&:hover': {
          backgroundColor: defaultColors.lightGrey,
        },
        '&:last-child': {
          borderBottom: 'none',
        },
      },
      bodyCell: {
        fontSize: '16px',
        letterSpacing: '0',
        color: defaultColors.darkGrey,
        fontWeight: 500,
      },
      orderCode: {
        width: '100%',
        textAlign: 'right',
        marginRight: '33px',
      },
      pack: {
        textAlign: 'center',
        backgroundColor: 'red',
      },
    },
  };
};

export default function UpcSelectionModal(props: IProps) {
  const sxProps = Array.isArray(props.sx) ? props.sx : [props.sx];
  const [activeSearchType, setActiveSearchType] = useState<string>(UpcSelectionSearchType.Upc);
  const [searchUpcValue, setSearchUpcValue] = useState<number | undefined>(undefined);
  const [searchOrderCodeValue, setSearchOrderCodeValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResponse, setSearchResponse] = useState<IUpcSearchForContractResponse>();
  const [dialogContentHeight, setDialogContentHeight] = useState<number>(0);
  const [searchContainerHeight, setSearchContainerHeight] = useState<number>(0);
  const [foundItemSelectedRowId, setFoundItemSelectedRowId] = useState<DataGridRowId[]>([]);
  const [associatedItemSelectedRowIds, setAssociatedItemSelectedRowIds] = useState<DataGridRowId[]>([]);
  const [missingOrderCode, setMissingOrderCode] = useState(false);
  const isShipper = useRef(false);

  const { referenceData } = useApplicationContextState();

  const dlgContentRef = useRef<HTMLInputElement>(null);
  const searchContainerRef = useRef<HTMLInputElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  const autoCompleteValue = useMemoForAutoCompleteValue();

  useLayoutEffect(() => {
    if (searchContainerRef?.current === null) return;
    const { height } = searchContainerRef.current.getBoundingClientRect();
    setSearchContainerHeight(height);
  });

  useLayoutEffect(() => {
    if (dlgContentRef?.current === null) return;
    const { height } = dlgContentRef.current.getBoundingClientRect();
    setDialogContentHeight(height);
  });

  useEffect(() => {
    refreshMissingOrderCode();
  }, [searchResponse]);

  const refreshMissingOrderCode = () => {
    const refreshValue = !!searchResponse?.foundItem && searchResponse.containedItems?.length > 0 && !searchResponse.foundItem.orderCode?.length;
    setMissingOrderCode(refreshValue);
    return refreshValue;
  };

  //OBSOLETE
  const isSecondaryUpc = searchResponse && searchResponse.notification && !searchResponse.notification.isError;

  const styles = myStyles({
    dialogContentHeight,
    searchContainerHeight,
    associatedItemsRowCount: (isShipper.current ? searchResponse?.containedItems.length : searchResponse?.associatedItems.length) || 0,
  });

  const handleSearchTypeChange = (event: React.MouseEvent<HTMLElement>, searchType: string) => {
    setActiveSearchType(searchType);
    setSearchUpcValue(undefined);
    setSearchOrderCodeValue('');
  };

  const handleSearch = async () => {
    if (activeSearchType === UpcSelectionSearchType.Upc && !searchUpcValue) {
      enqueueSnackbar('Please enter a UPC.', { variant: 'error' });
      return;
    }

    if (activeSearchType === UpcSelectionSearchType.OrderCode && !searchOrderCodeValue) {
      enqueueSnackbar('Please enter an order code.', { variant: 'error' });
      return;
    }

    if (!props.supplier) {
      enqueueSnackbar('Contract Supplier is required.', { variant: 'error' });
      return;
    }

    setIsLoading(true);
    setFoundItemSelectedRowId([]);
    setAssociatedItemSelectedRowIds([]);

    let response: IUpcSearchForContractResponse | HttpErrorResponse | undefined;

    const stores: number[] = [];
    stores.push(...props.storeSelections.storeIds);
    for (const storeGroupId of props.storeSelections.storeGroupIds) {
      if (!referenceData) continue;
      const storeGroup = referenceData.storeGroups.byId[storeGroupId];
      stores.push(...storeGroup.storeNumbers);
    }

    if (activeSearchType === UpcSelectionSearchType.Upc && searchUpcValue)
      response = await itemService.searchByUpcForContract(searchUpcValue, props.supplier.id, stores);
    else if (activeSearchType === UpcSelectionSearchType.OrderCode && searchOrderCodeValue)
      response = await itemService.searchByOrderCodeForContract(searchOrderCodeValue, props.supplier.id, stores);
    setIsLoading(false);

    if (!response || response instanceof HttpErrorResponse) {
      enqueueSnackbar('Unable to retrieve search results.', { variant: 'error' });
    } else {
      isShipper.current = response.containedItems?.length > 0;
      setSearchResponse(response);
      response.foundItem && setFoundItemSelectedRowId([response.foundItem.upc]);
      setAssociatedItemSelectedRowIds(
        isShipper.current ? response.containedItems.map((x) => x.item.upc) : response.associatedItems.map((x) => x.upc)
      );
    }
  };

  const handleAddToContract = () => {
    if (refreshMissingOrderCode()) {
      return;
    }
    if (!searchResponse) return;

    const contractItems: IContractItem[] = [];

    if (searchResponse.containedItems.length > 0 && searchResponse.foundItem && searchResponse.foundItem?.upc === foundItemSelectedRowId[0]) {
      contractItems.push({
        uniqueId: v4(),
        amounts: [],
        isShipperContainedItem: false,
        sku: searchResponse.foundItem.sku,
        item: searchResponse.foundItem,
        childData: searchResponse.containedItems.map(
          (contained) =>
            ({
              isShipperContainedItem: true,
              uniqueId: v4(),
              amounts: [],
              sku: contained.item.sku,
              item: { ...contained.item, quantityPerPack: contained.contentUPCQty },
              contentUPCQty: contained.contentUPCQty,
            }) as IChildContractItem
        ),
      });
    } else {
      if (associatedItemSelectedRowIds) {
        associatedItemSelectedRowIds.forEach((id) => {
          const responseItem = (isShipper.current ? searchResponse.containedItems.map((x) => x.item) : searchResponse.associatedItems).find(
            (i) => i.upc === id
          );
          responseItem &&
            contractItems.push({
              uniqueId: v4(),
              amounts: [],
              sku: responseItem.sku,
              item: responseItem,
            });
        });
      }

      if (foundItemSelectedRowId.length === 1 && searchResponse.foundItem?.upc === foundItemSelectedRowId[0]) {
        contractItems.push({
          uniqueId: v4(),
          amounts: [],
          sku: searchResponse.foundItem.sku,
          item: searchResponse.foundItem,
        });
      }
    }

    props.onAddToContract(contractItems);
  };

  const commonColumns: DataGridColDef[] = [
    {
      field: 'upc',
      headerName: 'UPC',
      sortable: false,
      width: 150,
      type: 'string',
    },
    {
      field: 'orderCode',
      headerName: 'Order Code',
      sortable: false,
      width: 125,
      type: 'string',
      renderBodyCellContent(params: DataGridRenderBodyCellParams<string, IItem>) {
        return <Box sx={styles.dataGrid.orderCode}>{params.value}</Box>;
      },
    },
    {
      field: 'quantityPerPack',
      headerName: 'Pack',
      sortable: false,
      width: 75,
      type: 'string',
    },
    {
      field: 'size',
      headerName: 'Size',
      sortable: false,
      width: 75,
      type: 'string',
      renderBodyCellContent(params: DataGridRenderBodyCellParams<string, IItem>) {
        return (
          <Box>
            {params.row.size} {(params.row.unitOfMeasure || '').trim().toLowerCase()}
          </Box>
        );
      },
    },
  ];

  const columns: DataGridColDef[] = [
    ...commonColumns,
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 1,
      type: 'string',
    },
    {
      field: 'isRandomWeight',
      headerName: '',
      sortable: false,
      width: 93,
      type: 'string',
      renderBodyCellContent(params: DataGridRenderBodyCellParams<string, IItem>) {
        return (
          <Box>
            <Tooltip title={params.row.isRandomWeight ? 'Random Weight' : ''}>{params.row.isRandomWeight ? <ScaleIcon /> : <></>}</Tooltip>
          </Box>
        );
      },
    },
  ];

  const shipperColumns: DataGridColDef[] = [
    ...commonColumns,
    {
      field: 'description',
      headerName: 'Description',
      sortable: false,
      flex: 1,
      type: 'string',
      renderBodyCellContent(params: DataGridRenderBodyCellParams<string, IItem>) {
        return isShipper.current ? (
          <Box sx={{ display: 'flex', flexFlow: 'row-reverse', width: '100%', alignItems: 'center' }}>
            <Box
              sx={{
                backgroundColor: defaultColors.darkGrey,
                color: 'white',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '2px',
                paddingBottom: '2px',
                borderRadius: '5px',
                flexShrink: 0,
              }}
            >
              Shipper
            </Box>
            <Box sx={{ marginRight: 'auto', overflow: 'hidden', textOverflow: 'ellipsis' }}>{params.row.description}</Box>
          </Box>
        ) : (
          <Box>{params.row.description}</Box>
        );
      },
    },
    {
      field: 'isRandomWeight',
      headerName: '',
      sortable: false,
      type: 'string',
      width: 93,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<string, IItem>) {
        return (
          <Box>
            {' '}
            <Tooltip title={params.row.isRandomWeight ? 'Random Weight' : ''}>{params.row.isRandomWeight ? <ScaleIcon /> : <></>}</Tooltip>
          </Box>
        );
      },
    },
  ];

  const associatedItemsLabel = () => {
    if (!searchResponse) return;
    if ((isShipper.current ? searchResponse.containedItems.length : searchResponse.associatedItems.length) <= 0) {
      return !searchResponse.notification ? 'No associated items.' : '';
    }

    const plurlOrNot = (isShipper.current ? searchResponse.containedItems.length : searchResponse.associatedItems.length) > 1;

    return `${isShipper.current ? searchResponse.containedItems.length : searchResponse.associatedItems.length} associated item${
      plurlOrNot ? 's' : ''
    }:`;
  };

  function compare(a: string | number, b: string | number) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  return (
    <>
      <Dialog id={`dialog${props.id}`} sx={[styles.dialog.root, ...sxProps]} open={props.open} disableEscapeKeyDown={true}>
        <DialogTitle sx={styles.dialog.title}>
          <ClearIcon sx={styles.dialog.title.closeIcon} onClick={props.onCancel} />
        </DialogTitle>
        <DialogContent sx={styles.dialog.content} ref={dlgContentRef}>
          <Box sx={styles.search} ref={searchContainerRef}>
            <Box sx={styles.search.instructions}>Add item(s) to contract by UPC or order code.</Box>
            <Box sx={styles.search.form}>
              <ToggleButtonGroup sx={styles.search.form.toggle} value={activeSearchType} exclusive onChange={handleSearchTypeChange}>
                <ToggleButton
                  sx={styles.search.form.toggle.upc}
                  value={UpcSelectionSearchType.Upc}
                  disabled={activeSearchType === UpcSelectionSearchType.Upc}
                >
                  UPC
                </ToggleButton>
                <ToggleButton
                  sx={styles.search.form.toggle.orderCode}
                  value={UpcSelectionSearchType.OrderCode}
                  disabled={activeSearchType === UpcSelectionSearchType.OrderCode}
                >
                  Order Code
                </ToggleButton>
              </ToggleButtonGroup>
              {activeSearchType === UpcSelectionSearchType.Upc && (
                <OutlinedInput
                  type="number"
                  value={searchUpcValue || ''}
                  onChange={(e) => setSearchUpcValue(parseInt(e.target.value))}
                  onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  sx={[
                    styles.search.form.input,
                    searchResponse && searchResponse.notification?.isError ? styles.search.form.input.error : styles.search.form.input,
                  ]} //todo: fix logic
                  autoComplete={autoCompleteValue}
                />
              )}
              {activeSearchType === UpcSelectionSearchType.OrderCode && (
                <OutlinedInput
                  value={searchOrderCodeValue || ''}
                  onChange={(e) => setSearchOrderCodeValue(e.target.value)}
                  onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
                  sx={[
                    styles.search.form.input,
                    searchResponse && searchResponse.notification?.isError ? styles.search.form.input.error : styles.search.form.input,
                  ]} //todo: fix logic
                  autoComplete={autoCompleteValue}
                />
              )}

              <DbgRoundedButton
                sx={styles.search.form.button}
                styleProps={{ ...getWhiteButtonStyleProps(), width: '155px' }}
                id={'Search'}
                onClick={handleSearch}
                disabled={isLoading}
              >
                {isLoading ? (
                  <DbgLoadingSpinner id="loading" sx={[styles.search.form.button.searchIcon, styles.search.form.button.searchIconLoading]} />
                ) : (
                  <StyledImg src={SearchIcon} sx={styles.search.form.button.searchIcon} />
                )}
                Search
              </DbgRoundedButton>
            </Box>
            {searchResponse && searchResponse.notification && <UpcSearchNotification notification={searchResponse.notification} />}
          </Box>

          {searchResponse?.foundItem && (
            <Box sx={[styles.dataGridContainer, styles.dataGridContainer.foundItem]}>
              <Box sx={styles.dataGridContainer.heading}>
                {isShipper.current ? `Shipper found` : `Item ${isSecondaryUpc ? 'replaced' : 'found'}`}:
              </Box>
              <DataGrid
                id={'upc-found-item-data-grid'}
                rows={[searchResponse.foundItem]}
                getRowId={(row: IItem) => row.upc}
                columns={shipperColumns}
                bodyRowHeight={59}
                hideFooter
                checkboxSelection={true}
                onRowSelectionChange={(selections: DataGridRowId[]) => setFoundItemSelectedRowId([...selections])}
                rowSelections={foundItemSelectedRowId}
                cssOverrides={{
                  root: styles.dataGrid.root,
                  header: styles.dataGrid.header,
                  headerCell: { ...styles.dataGrid.headerCell, ...styles.dataGrid.headerCellSingleRow },
                  bodyRow: styles.dataGrid.bodyRow,
                  bodyCell: styles.dataGrid.bodyCell,
                }}
                onRowClick={(row: DataGridRowModel) => {
                  if (foundItemSelectedRowId.find((q) => q === row.upc)) setFoundItemSelectedRowId([]);
                  else setFoundItemSelectedRowId([row.upc]);
                }}
              />
            </Box>
          )}
          {!searchResponse?.foundItem && (
            <Box sx={[styles.dataGridContainer]}>
              <Box sx={styles.dataGridContainer.heading}>No Item found.</Box>
            </Box>
          )}
          <Box sx={[styles.dataGridContainer, styles.dataGridContainer.associatedItems]}>
            <Box sx={styles.dataGridContainer.heading}>{isShipper.current ? 'Shipper contains:' : associatedItemsLabel()}</Box>
            {searchResponse && (isShipper.current ? searchResponse.containedItems.length > 0 : searchResponse.associatedItems.length > 0) && (
              <DataGrid
                id={'upc-found-item-associated-items-data-grid'}
                rows={(isShipper.current
                  ? searchResponse.containedItems.map((x) => ({ ...x.item, quantityPerPack: x.contentUPCQty }))
                  : searchResponse.associatedItems
                )
                  .sort((a, b) => compare(a.upc, b.upc))
                  .map((x) => x)}
                getRowId={(row: IItem) => row.upc}
                columns={columns}
                bodyRowHeight={59}
                hideFooter
                checkboxSelection={!isShipper.current}
                onRowSelectionChange={(selections: DataGridRowId[]) => setAssociatedItemSelectedRowIds([...selections])}
                rowSelections={associatedItemSelectedRowIds}
                cssOverrides={{
                  root: styles.dataGrid.root,
                  header: styles.dataGrid.header,
                  headerCell: {
                    ...styles.dataGrid.headerCell,
                    ...((isShipper.current ? searchResponse.containedItems.length === 1 : searchResponse.associatedItems.length === 1)
                      ? styles.dataGrid.headerCellSingleRow
                      : {}),
                  },
                  bodyRow: styles.dataGrid.bodyRow,
                  bodyCell: styles.dataGrid.bodyCell,
                }}
                onRowClick={(row: DataGridRowModel) => {
                  const match = associatedItemSelectedRowIds.find((q) => q === row.upc);
                  if (match) setAssociatedItemSelectedRowIds(associatedItemSelectedRowIds.filter((q) => q !== row.upc));
                  else setAssociatedItemSelectedRowIds([...associatedItemSelectedRowIds, row.upc]);
                }}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions sx={styles.dialog.actions}>
          <DbgRoundedButton
            id="AddToContract"
            dbgButtonSize={DbgButtonSize.Large}
            disabled={isLoading || (foundItemSelectedRowId.length === 0 && associatedItemSelectedRowIds.length == 0)}
            styleProps={
              foundItemSelectedRowId.length === 1 || associatedItemSelectedRowIds.length > 0
                ? getGreenButtonStyleProps()
                : getLightGreyButtonStyleProps()
            }
            sx={styles.dialog.actions.btnAddToContract}
            onClick={handleAddToContract}
          >
            + Add to Contract
          </DbgRoundedButton>
        </DialogActions>
      </Dialog>
      <Dialog open={missingOrderCode} id="missingOrderCode">
        <DialogTitle sx={styles.dialog.title}>
          <ClearIcon sx={styles.dialog.title.closeIcon} onClick={() => setMissingOrderCode(false)} />
        </DialogTitle>
        <DialogContent>
          <Box style={styles.missingOrderCode.content}>The shipper does not have an order code. Please contact CM for help.</Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
